import React from 'react';
import BaseSecure from './base_templates/BaseSecure';

const PreviewBase = ({ mode, type, locale, handleChangeLocale, children }) =>
  type === 'Secure' ? (
    <BaseSecure
      mode={mode}
      locale={locale}
      handleChangeLocale={handleChangeLocale}
    >
      {children}
    </BaseSecure>
  ) : null;

export default PreviewBase;
