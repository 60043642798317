// Using directly for now to be safe. Can use polyfill in future.
import 'isomorphic-fetch';
import { useState, useEffect, useCallback } from 'react';

const useFetch = (url, { initialData = [] } = {}) => {
  const [status, setStatus] = useState('idle');
  const [data, setData] = useState(initialData);
  const [error, setError] = useState([]);

  const fetchData = useCallback(
    async (body) => {
      setStatus('fetching');
      try {
        const response = await fetch(url, {
          ...(body && { body }),
        });
        const responseData = await response.json();
        setData(responseData);
      } catch (e) {
        setError(e);
      }
      setStatus('fetched');
    },
    [url],
  );

  useEffect(() => {
    if (!url) return;
    fetchData();
  }, [url, fetchData]);

  const isLoading = status === 'fetching';

  return [isLoading, data, error, fetchData];
};

export default useFetch;
