import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import { selectBox } from '../inputs';
import { textArea } from '../inputs';
import { validations } from '../../helperFunctions';
import { SelectField, TextArea } from '../../modules/components/inputs';

const CancelReason = ({
  id,
  setLoader,
  closeModal,
  getEvents,
  role,
  organizationID,
  customClass,
  pageName,
  cancelOnProvider = false,
  cancellationPollingTimeout = 600,
  patientId = '',
  stringValues = {},
}) => {
  const [selectedOption, setSelectedOption] = useState(false);
  const [statusChangeOptions, setStatusChangeOptions] = useState({ data: [] });
  useEffect(() => {
    statusChangeReasons();
  }, []);
  const startTime = React.useRef();

  const statusChangeReasons = () => {
    const headers = {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
    };

    const data = {
      role: role || 'provider_or_scheduling_admin',
      organization_id: organizationID,
    };

    axios
      .get(
        `/open_filters/status_change_reason?${data.role}`,
        { params: data },
        {
          headers: headers,
        },
      )
      .then(function (res) {
        console.log('Created', res.data);
        setLoader(false);
        setStatusChangeOptions(res.data);
      })
      .catch(function (err) {
        setLoader(false);
        const msg =
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        alert(msg);
      });
  };

  const continueHandleSubmit = (values) => {
    const headers = {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
    };
    const { cancel_reason, comments } = values;
    const data = {
      status: 'cancelled',
      changed_at: new Date().toISOString(),
      encounter_id: id,
      status_change_reason_id: cancel_reason,
      comments: comments,
      role: role || 'provider_or_scheduling_admin',
    };
    let statusChangeURL = `/api/v1/visits/${id}/status_change`;
    let cancelVisitURL = `/api/v1/visits/${id}`;
    if (validations.isNotNull(role) && role === 'member') {
      statusChangeURL = `/member_self_serve/status_change?id=${id}`;
      cancelVisitURL = `/member_self_serve/update_status?id=${id}`;
    }
    axios
      .post(statusChangeURL, data, {
        headers: headers,
      })
      .then(function (res) {
        console.log('Created', res.data);
        closeModal('success');
        return res.data;
      })
      .catch(function (err) {
        setLoader(false);
        const msg =
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        alert(msg);
      });

    const data1 = {
      visit_status: 'cancelled',
      pageName: pageName || 'Scheduling Page',
    };

    setLoader(true);
    axios
      .patch(cancelVisitURL, data1, { headers })
      .then((res) => {
        setLoader(false);
        getEvents();
        return res.data;
      })
      .catch((err) => {
        setLoader(false);
        const msg =
          err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        alert(msg);
      });
  };

  const pollingCall = (transactionId, values) => {
    axios
      .get(`/call_centers/transaction_confirmation`, {
        params: {
          transaction_id: transactionId,
          patient_id: patientId,
        },
      })
      .then((response) => {
        const currentTime = Date.now() / 1000;
        if (response.data.status === 'success') {
          continueHandleSubmit(values);
        } else if (
          currentTime - startTime.current > cancellationPollingTimeout ||
          response.data.status === 'failed'
        ) {
          alert(
            'We’re sorry, but your original appointment can no longer be modified by Lucet. Please contact the provider directly for any appointment changes needed.',
          );
          setLoader(false);
          closeModal();
          getEvents();
        } else {
          pollingCall(transactionId, values);
        }
      });
  };

  const handleVisitCancellationRequest = (values) => {
    const { cancel_reason, comments } = values;
    axios
      .get(`/call_centers/create_visit_cancellation_request_transaction`, {
        params: {
          encounter_id: id,
          patient_id: patientId,
          status: 'cancelled',
          changed_at: new Date().toISOString(),
          comments: comments,
          status_change_reason_id: cancel_reason,
          role: role || 'provider_or_scheduling_admin',
        },
      })
      .then((res) => {
        startTime.current = Date.now() / 1000;
        pollingCall(res.data.transaction_id, values);
      });
  };

  const handleSubmit = (values) => {
    setLoader(true);
    if (cancelOnProvider) {
      handleVisitCancellationRequest(values);
    } else {
      continueHandleSubmit(values);
    }
  };

  return (
    <React.Fragment>
      <div className={`${customClass}`} style={{ display: 'block' }}>
        <Formik
          initialValues={{
            cancel_reason: '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              handleSubmit(values);
              setSubmitting(false);
            }, 400);
          }}
        >
          <Form className={'cancel-reason-form'}>
            <h3 className="text-8 text-center">
              {stringValues.cancel_appointment || 'Cancel Appointment?'}
            </h3>

            <div className="row-block">
              <Field
                placeholder="Select Option"
                name="cancel_reason"
                component={selectBox}
                label={
                  stringValues.why_cancel_this_appt ||
                  'Why are you cancelling this appointment?'
                }
                options={statusChangeOptions.data}
                lucetTheme
                onChange={(e) => {
                  statusChangeOptions.data.forEach((element) => {
                    if (element.value === e) {
                      setSelectedOption(element.comment_flag);
                    }
                  });
                }}
                validate={validations.required}
              />
            </div>
            {selectedOption && (
              <TextArea
                placeholder="Select Option"
                name="comments"
                component={textArea}
                label="Enter your comments"
                validate={validations.required}
              />
            )}

            <div className="modal-footer">
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-default"
              >
                Back
              </button>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default CancelReason;
