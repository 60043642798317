import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import styles from './custom_messages.module.scss';
import PreviewBody from './PreviewBody';
import PreviewBase from './PreviewBase';

const PreviewMessage = ({ template, mode = 'view', filterData = {} }) => {
  const [locale, setLocale] = useState('en');

  const handleChangeLocale = useCallback((newLocale) => {
    setLocale(newLocale);
  }, []);

  return (
    <PreviewBase
      type={template?.delivery_methods_label}
      locale={locale}
      mode={mode}
      handleChangeLocale={handleChangeLocale}
    >
      <div className={clsx(styles.previewContainer)}>
        <PreviewBody
          messageInstanceId={template?.id}
          filterData={filterData}
          mode={mode}
          locale={locale}
          handleChangeLocale={handleChangeLocale}
        />
      </div>
    </PreviewBase>
  );
};

export default PreviewMessage;
