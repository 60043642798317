import React from 'react';
import useFilterDataAsUrl from '../../../../hooks/useFilterDataAsUrl';
import PreviewMessage from '../../../../components/custom_messages/PreviewMessage';
import useFetch from '../../../../hooks/useFetch';

const ShowSecuredMessage = () => {
  const [, params] = useFilterDataAsUrl();
  const [isLoading, data] = useFetch(
    `?secured_message_id=${params.secured_message_id}&patient_id=${params.patient_id}`,
  );
  return (
    <div>
      <PreviewMessage />
    </div>
  );
};

export default ShowSecuredMessage;
