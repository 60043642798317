export const EN_TRANSLATION = {
  valid_date: 'Please enter a valid date.',
  valid_year: 'Please enter a valid year.',
  valid_month: 'Please enter a valid month.',
  valid_day: 'Please enter a valid day.',
  valid_name: 'Please enter a valid name.',
  valid_mrn: 'Please enter a valid MRN.',
  valid_value: 'Please enter a valid value.',
  valid_phone_number: 'Please enter a valid phone number.',
  valid_area_code:
    'Please enter a valid area code starting with a number 2 - 9.',
  valid_required: 'Please fill out this field.',
  valid_email: 'Please enter a valid email address.',
  valid_checkbox: 'Please check this checkbox.',
  valid_min_value: 'Value must be greater than or equal to %{minValue}',
  valid_zip: 'Please enter a valid zip code.',
  valid_zip_format: 'Please enter a valid zip code (#####-####).',
  valid_suffix:
    'Please select valid suffix(es) or clear the suffix and consider adding a title.',
  valid_honorific:
    'Please select a valid title or select “No Title” and consider adding a suffix.',
  email_or_phone: 'Please select email and/or text.',
  authentication_form: 'Authentication Form',
  change_language: 'Change Language',
};

export const ES_TRANSLATION = {
  valid_date: 'Por favor ingrese un fecha válido.',
  valid_year: 'Por favor ingrese un año válido.',
  valid_month: 'Por favor ingrese un mes válido.',
  valid_day: 'Por favor ingrese un día válido.',
  valid_name: 'Por favor ingrese un nombre válido.',
  valid_mrn: 'Por favor ingrese un MRN válido.',
  valid_value: 'Por favor ingrese un valor válido.',
  valid_phone_number: 'Por favor ingrese un número de teléfono válido.',
  valid_area_code:
    'Ingrese un código de área válido que comience con un número del 2 al 9.',
  valid_required: 'Por favor rellene este campo.',
  valid_email: 'Por favor ingrese una dirección de correo electrónico válida.',
  valid_checkbox: 'Marque esta casilla de verificación.',
  valid_min_value: 'El valor debe ser mayor o igual que %{minValue}',
  valid_zip: 'Por favor ingrese un código postal válido.',
  valid_zip_format: 'Por favor ingrese un código postal válido (#####-####).',
  valid_suffix: 'Por favor seleccione sufijo(s) válido(s).',
  valid_honorific: 'Por favor seleccione un título válido.',
  email_or_phone:
    'Por favor seleccione correo electrónico y/o mensaje de texto.',
  authentication_form: 'Formulario de autenticación',
  change_language: 'Cambiar idioma',
};

export class LanguageTranslator {
  static getTranslations = (locale) => {
    let translations;
    switch (locale) {
      case 'es':
        translations = ES_TRANSLATION;
        break;
      default:
        translations = EN_TRANSLATION;
        break;
    }
    return translations;
  };

  static translate = (locale, val) =>
    LanguageTranslator.getTranslations(locale)[val];
}
